import React, {useEffect, useState} from 'react';
import './DashboardComponent.css';
import Logo from './res/logo_morgana.png';
import DashIcon from './res/dashboard.png';
import CompIcon from './res/components.png';

export default function DashboardComponent()
{
    const menuItems =[
        {
            title: 'Dashboard',
            icon: DashIcon,
            id: 'dashboard'
        },
        {
            title: 'Components',
            icon: CompIcon,
            id: 'components'
        }
    ]
    const [selectedScreen, setSelectedScreen] = useState('dashboard')

    const decideScreen = (id)=>{
        switch(id)
        {
            case 'dashboard':
            return <Dashboard/>

            case 'components':
                return <Components/>

            default:
                return <Dashboard/>
        }
    }
    return(
        <div className='morgana_dash-container'>
            <div className='morgana_dash-container-menu'>
                <div className='morgana_dash-container-menu-logo'>
                    <img src={Logo}/>
                    <span>Morgana Pipeline</span>
                </div>
                <div className='morgana_dash-container-menu-items'>
                    {
                        menuItems.map((el)=>{
                            return (
                                <div 
                                    className='morgana_dash-container-menu-item'
                                    onClick={()=>{
                                        setSelectedScreen(el.id)
                                    }}
                                    >
                                    <img src={el.icon}/>
                                    <span>{el.title}</span>
                                </div>
                            )
                        })
                    }
                </div>

            </div>
            <div className='morgana_dash-container-content'>
                {
                    decideScreen(selectedScreen)
                }
            </div>
        </div>
    )
}

const Dashboard = ()=>{
    return (
        <p>dash</p>
    )
}

const Components = ()=>{
    return (
        <p>comp</p>
    )
}