import React,{useState, useEffect, useRef} from 'react';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../Style/Theme';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../core/utils/useOnScreen';
import Modal from '@mui/material/Modal';
import './coreStyle.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const defaultItems = [
    {
        name: 'Default Name',
        headline: 'Default Headline',
        img: defaultBgImage[1],
        desc: 'Default Widget Desc'
    },
    {
        name: 'Default Name',
        headline: 'Default Headline',
        img: defaultBgImage[1],
        desc: 'Default Widget Desc'
    },
]
export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic
    const id = logic?.id ? logic.id : 'widget-10-id';

    //resources
    const swordItem = resources?.swordItem ? resources.swordItem : defaultLogo;
    const itemsTop = resources?.itemsTop ? resources.itemsTop : defaultItems
    const itemsBot = resources?.itemsBot ? resources.itemsBot : defaultItems
    const img = resources?.img ? resources.img : defaultImage[1];
    const modal = resources?.modal ? resources.modal : defaultLogo;

    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage[1]}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    // atoms
    const title = atoms?.title !== undefined ? atoms.title : undefined;


    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    return(
        <div
            id={id}
            ref={ref}
            style={{
                minHeight: '40vh',
            }}
        >
                {alreadyVisible  == true ?  (
            <motion.div 
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_widget_10-container'
                style={{
                    fontFamily: theme.fonts.title,
                    ...bg
                }}
                >
                    <div style={{
                        width: '100%',
                    }}>
                        <Item  modal={modal} theme={theme} {...resources.specialItem}/>
                    </div>
                    {
                        title?.item ? 
                        <title.item {...title.props}/>
                        :
                        <p>Default title</p>
                    }
                    <div className='layers_widget_10-container-top'>
                    
                        {/* <div className='layers_widget_10-container-top-left'>
                            <div
                                
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    border: '1px solid red'
                                }}
                            >
                                <img 
                                    src={swordItem}
                                    className='layers_widget_10-container-top-left-special'
                                    style={{
                                        height: '40px',
                                        objectFit: 'contain'
                                    }}
                                    />
                                <Item  theme={theme} modal={modal} {...itemsTop[0]}/>
                                <img 
                                    className='layers_widget_10-container-top-left-special'
                                    src={swordItem}
                                    style={{
                                        height: '40px',
                                        objectFit: 'contain',
                                        transform: 'rotate(180deg)'
                                    }}
                                    />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                }}
                            >
                                {
                                    itemsTop.slice(1).map((el)=>{
                                        return(<Item  theme={theme} modal={modal} {...el}/>)
                                    })
                                }   
                            </div>
                            
                        </div> */}

                        <div className='layers_widget_10-container-top-left'>
                        {
                            itemsTop.map((el)=>{
                                return(<Item  modal={modal} theme={theme} {...el}/>)
                            })
                        }  
                        </div>
                        <div className='layers_widget_10-container-top-right'>
                            <img src={img} alt="widget"/>
                        </div>  

                    </div>
                    <div className='layers_widget_10-container-bot'>
                        {
                            itemsBot.map((el)=>{
                                return(<Item  modal={modal} theme={theme} {...el}/>)
                            })
                        }  
                    </div>
                </motion.div>):null}
        </div>
    )
}


const Item = ({name, headline, img, desc,modal,theme})=>{

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '50%',
        boxShadow: 24,
        padding: '10px',
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        backgroundColor: 'white',
      };
    console.log("theme:", theme);
    return(
        <div 
            
            className='layers_widget_10-container-item'>
            <img src={img}/>
            <div className='layers_widget_10-container-item-name'>
                <span>{name}</span>
            </div>
            <div className='layers_widget_10-container-item-headline'>
                <span>{headline}</span>
            </div>
            <div className='layers_widget_10-container-item-modal'>
                <img onClick={handleOpen} src={modal}/>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} >
                    <img className="layers_widget_10-container-item-modal-core-img" style={{height: '200px'}} src={img}/>
                    <div 
                        style={{
                            fontFamily: theme.fonts.title,
                            textAlign: 'center'
                        }}
                        className='layers_widget_10-container-item-modal-core'>
                        <div className='layers_widget_10-container-item-name'>
                            <span>{name}</span>
                        </div>
                        <div className='layers_widget_10-container-item-headline'>
                            <span>{headline}</span>
                        </div>
                        <div className='layers_widget_10-container-item-desc'>
                            <span>{desc}</span>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
