import React,{useState, useEffect, useRef} from 'react';
import './coreStyle.css';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../Style/Theme';
import { useOnScreen } from '../../../core/utils/useOnScreen';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic
    const id = logic?.id ? logic.id : 'widget-6-id';

    // resources
    const logo = resources?.logo ? resources.logo : defaultLogo;
    const title = resources?.title ? resources.title : 'Default Title';
    const desc = resources?.desc ? resources.desc : 'Default Desc';
    const decor = resources?.decor ? resources.decor : defaultLogo;
    const sponsors = resources?.sponsors ? resources.sponsors : [defaultImage,defaultImage,defaultImage]
    const slider = resources?.slider ? resources.slider : [defaultImage[0], defaultImage[1]]
    const sward = resources?.sward ? resources.sward : defaultLogo;

    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    const formatFirstLetter = (source)=>{
        console.log("title:",title)
        return (
          <>
           <span 
                style={{
                fontFamily: theme.fonts.content,
                fontWeight: 'bold',
                fontSize: '3rem',
                paddding: '10px',
              }}>{source[0]}</span>
            <span 
                style={{
                fontFamily: theme.fonts.title
              }}><i>{source.substring(1)} </i></span>
            
          </>
        )
      }

    return(
        <div
            id={id}
            ref={ref}
            style={{
                minHeight: '40vh',
            }}
        >
             {alreadyVisible  == true ?  (
            <motion.div 
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_widget_6-container'
                style={{
                    backgroundSize: '100% 100%',
                    // height: '90vh',
                    ...bg
                }}
                >
                    <div className='layers_widget_6-container-top'>
                        <div className='layers_widget_6-container-left'>
                            <div className='layers_widget_6-container-left-logo'>
                                <img src={logo} alt="logo"/>
                            </div>
                            <div 
                                style={{
                                    fontFamily: theme.fonts.title
                                }}
                                className='layers_widget_6-container-left-content'>
                                <div className='layers_widget_6-container-left-content-title'>
                                    <i><p>{formatFirstLetter(title)}</p></i>
                                </div>
                                <div className='layers_widget_6-container-left-content-desc'>
                                    <p>{desc}</p>
                                </div>
                            </div>
                            <div className='layers_widget_6-container-left-decor'>
                                <img src={decor} alt="logo"/>
                            </div>
                        </div>
                        <div className='layers_widget_6-container-right'>
                            <div 
                                style={{
                                    '--swiper-theme-color': 'black',
                                }}
                                className='layers_widget_6-container-right-myswiper'>
                                <Swiper navigation={true} modules={[Navigation]} >
                                    {
                                        slider.map((el)=>{
                                            return(
                                                <SwiperSlide>
                                                <SwiperSlideCustom img={el} sward={sward}/>
                                            </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className='layers_widget_6-container-bot'>
                        {
                            sponsors.map((el)=>{
                                return(
                                    <div className='layers_widget_6-container-bot-item'>
                                        <img src={el} alt="sponsor"/>
                                    </div>
                                )
                            })
                        }
                    </div>
                    
                    
            </motion.div>):null}
        </div>
    )
}

const SwiperSlideCustom = ({img,sward})=>{
    return(
        <div className='layers_widget_6-container-right-myswiper-item'>
            <div className='layers_widget_6-container-right-myswiper-itema-top'>
                <img src={img} alt="activities"/>
            </div>
            <div className='layers_widget_6-container-right-myswiper-itema-bot'>
                <img src={sward} alt="sward"/>
            </div>

        </div>
    )
}