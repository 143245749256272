import React,{useEffect, useState} from 'react';

export default function StickyBombMorgana({metadata})
{
    useEffect(()=>{
        document.title = metadata.title
        //TODO: Insert page description using react helmet
    },[metadata])

    return(
        <></>
    )
}