import React,{useState, useEffect, useRef} from 'react';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../Style/Theme';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../core/utils/useOnScreen';
import './coreStyle.css';


const defaultMap = 'https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d11169.83686188473!2d27.612623196925128!3d47.158572395396064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sro!4v1682974035318!5m2!1sen!2sro'
export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic

    //resources
    const decor1 = resources?.decor1 ? resources.decor1 : defaultLogo;
    const decor2 = resources?.decor2 ? resources.decor2 : defaultLogo;
    const map = resources?.map ? resources.map : defaultMap
    const contact = resources?.contact ? resources.contact : [{
        name: 'default contact',
        icon: defaultLogo
    }]
    const social = resources?.social ? resources.social : [
        {
            icon: defaultLogo,
            link: 'https://www.google.com'
        }
    ]
    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage[1]}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    // atoms
    const title = atoms?.title !== undefined ? atoms.title : undefined;
    const button = atoms?.button !== undefined ? atoms.button : undefined;

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    return(
        <div
            ref={ref}
            style={{
                minHeight: '40vh',
            }}
        >
                {alreadyVisible  == true ?  (
            <motion.div 
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_widget_12-container'
                style={{
                    fontFamily: theme.fonts.title,
                    ...bg
                }}
                >
                    <div className='layers_widget_12-container-left'>
                    {
                        title?.item ? 
                        <title.item {...title.props}/>
                        :
                        <p>Default title</p>
                    }
                    <div className='layers_widget_12-container-left-contacts'>
                    {
                        contact.map((el)=>{
                            return(<Item {...el} theme={theme}/>)
                        })
                    }
                    </div>
                    <div className='layers_widget_12-container-left-socials'>
                    {
                        social.map((el)=>{
                           return(
                            <a href={el.link}>
                                <img src={el.icon}/>
                            </a>
                           )
                        })
                    }
                    </div>
                    </div>
                    <div className='layers_widget_12-container-right'>
                        <div className='layers_widget_12-container-right-map'>
                            <img 
                                style={{
                                    marginBottom: '30px',
                                }}
                                src={decor2}/>
                            <iframe 
                                src={map} 
                                style={{
                                    borderRadius: '20px',
                                    outline: 'none'
                                }}
                                width="500" 
                                height="300"  
                                allowfullscreen="" 
                                loading="lazy" 
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className='layers_widget_12-container-right-decor'>
                            <img 
                                src={decor1}/>
                            <img 
                                style={{
                                    transform: 'rotate(270deg) translateX(70px)'
                                }}
                                src={decor1}/>
                        </div>
                    </div>
                </motion.div>):null}
        </div>
    )
}

const Item = ({name, icon, theme})=>{
    return(
        <div className='layers_widget_12-container-left-contact'>
            <img src={icon}/>
            <span>{name}</span>
        </div>
    )
}