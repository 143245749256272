import {useState, useEffect, useRef} from 'react';
import { useInView } from "react-intersection-observer";
import './coreStyle.css';
import { defaultTheme,DefaultRevealAnimation,defaultImage } from '../../../../Style/Theme';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../../core/utils/useOnScreen';


export default function CoreModule({
    logic, 
    resources, 
    themeParam, 
    customStyle, //only for atoms, widgets have style props in resources
    revealAnimationParam, 
    //atoms -atoms can't contain atoms
  })
  {
      //logic
      const {} = logic ? logic : {};
  
      //resources
      const title = resources?.title ? resources.title : 'Default Title';
      const decor = resources?.decor ? resources.decor : defaultImage[1];
      
      //theme
      const theme = themeParam ? themeParam : defaultTheme;
  
      //revealAnimation (!! atoms have a single reveal animation, widgets have more)
      const revealAnimation = revealAnimationParam ? revealAnimationParam : DefaultRevealAnimation;
  
      // customStyle
      const parent = customStyle?.parent ? customStyle.parent : {};

      const { hidden, show } =  revealAnimation
      //trigger reveal animation logic
      const [alreadyVisible, setAlreadyVisible] = useState(false);
      const ref = useRef(null);
      const isVisible = useOnScreen(ref, 0.5);
      useEffect(()=>{
          if(isVisible == true && alreadyVisible == false)
          {
              setAlreadyVisible(true);
          }
      },[isVisible])
    
      // component logic
      const formatFirstLetter = (source)=>{
        console.log("title:",title)
        return (
          <>
           <span 
                style={{
                fontFamily: theme.fonts.content,
                fontWeight: 'bold',
                fontSize: '3rem',
                paddding: '10px',
              }}>{source[0]}</span>
            <span 
                style={{
                fontFamily: theme.fonts.title
              }}><i>{source.substring(1)} </i></span>
            
          </>
        )
      }
      return (
        <div
          ref={ref}
          style={{
            //component must have fixed height for the reveal animation to run
            minHeight: '10px',
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {alreadyVisible == true ? (
            <motion.div 
              variants={{ hidden, show }} 
              initial="hidden" 
              animate="show"
              style={{
                fontFamily: theme.fonts.title,
                  ...parent,
              }}
              >
              <div className='atoms_buttons_title_1-header'>
                    <div
                        style={{
                            transform: 'translateX(20px) translateY(20px)'
                        }} 
                        className='atoms_buttons_title_1-header-decor'>
                        <img src={decor} alt="decor"/>
                    </div>
                    <div className='atoms_buttons_title_1-header-title'>
                        {/* <span>{title}</span> */}
                        {formatFirstLetter(title)}
                    </div>
                    <div
                        style={{
                            transform: 'rotate(270deg) translateY(-20px) translateX(-20px)',
                            
                        }} 
                        className='atoms_buttons_title_1-header-decor'>
                        <img src={decor} alt="decor"/>
                    </div>
                </div>
            </motion.div>
          ):null}
        </div>
      );
  }