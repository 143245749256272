import React,{useState, useEffect, useRef} from 'react';
import './coreStyle.css';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../Style/Theme';
import { useOnScreen } from '../../../core/utils/useOnScreen';
import { motion } from 'framer-motion';

export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic
    const id = logic?.id ? logic.id : 'widget-7-id'
    //resources
    const sword = resources?.sword ? resources.sword : defaultLogo;
    const img = resources?.img ? resources.img : defaultImage[0];
    const title = resources?.title ? resources.title : "Default Title";
    const content = resources?.content ? resources.content : "Default Content";


    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage[1]}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    // atoms
    const button = atoms?.button !== undefined ? atoms.button : undefined;

    
    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

     const formatFirstLetter = (source)=>{
        console.log("title:",title)
        return (
          <>
           <span 
                style={{
                fontFamily: theme.fonts.content,
                fontWeight: 'bold',
                fontSize: '3rem',
                paddding: '10px',
              }}>{source[0]}</span>
            <span 
                style={{
                fontFamily: theme.fonts.title
              }}><i>{source.substring(1)} </i></span>
            
          </>
        )
      }

    return(
        <div
            id={id}
            ref={ref}
            style={{
                minHeight: '40vh',
            }}
        >
                {alreadyVisible  == true ?  (
            <motion.div 
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_widget_7-container'
                style={{
                    ...bg
                }}
                >
                    <div className='layers_widget_7-container-left'>
                        <div className='layers_widget_7-container-left-dec1'>
                            <img src={sword} />
                        </div>
                        <div className='layers_widget_7-container-left-content'>
                            <div className='layers_widget_7-container-left-content-top'>
                                <div 
                                    style={{
                                        fontFamily: theme.fonts.title
                                    }}
                                    className='layers_widget_7-container-left-content-top-title'>
                                    <p>{formatFirstLetter(title)}</p>
                                </div>
                                <div 
                                    style={{
                                        fontFamily: theme.fonts.title
                                    }}
                                    className='layers_widget_7-container-left-content-top-content'>
                                    <p>{content}</p>
                                </div>
                                <div className='layers_widget_7-container-left-content-top-btn'>
                                {
                                    button?.item ? 
                                    <button.item {...button.props}/>
                                    :
                                    <DefaultButton/>
                                }
                                </div>

                            </div>
                            <div className='layers_widget_7-container-left-content-dec2'>
                                <img src={sword} />
                            </div>
                        </div>


                    </div>
                    <div className='layers_widget_7-container-right'>
                        <img src={img} alt="child"/>
                    </div>

                    
            </motion.div>):null}
        </div>
    )
}