import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import StickyBombMorgana from './StickyBombMorgana';
import DevLibrary from "./DevLibrary";
export function routerDomSpreadCore(config)
{
    const buildPage = (metadata, NavBar, Layers, Footer)=>{
        return(
            <>
                <StickyBombMorgana metadata={metadata}/>
                {NavBar}
                <>
                    {Layers.map(el=>el)}
                </>
                {Footer}
            </>
        )
    }
    
    return(
        <>
            <Router>
                <Routes>
                    {config.content.pages.map((page)=>{
                    return <Route path={page.url} element={buildPage(page.metadata , config.content.appBar,page.components, config.content.footer)}></Route>
                    })}
                    <Route path="/dev" element={<DevLibrary/>}/>
                </Routes>
            </Router>
        </>
    )
}