import React,{useState, useEffect, useRef} from 'react';
import "./coreStyle.css";
import { useOnScreen } from '../../../../core/utils/useOnScreen';
import { motion } from 'framer-motion';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../../Style/Theme'

export default function CoreModule({
    resources,
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    // resources
    const links = resources?.links ? resources.links : [{
        title: 'default',
        toId: '#id1'
    },]

    //theme
    const theme = themeParam ? themeParam: defaultTheme;

    // customStyles
    const bgColor = customStyle?.bgColor ? customStyle.bgColor : theme.colors.color_30

    // atoms
    const button = atoms?.button !== undefined ? atoms.button : undefined;
    
    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    // component logic
    const [burgerMenu, setBurgerMenu] = useState(false);
    useEffect(()=>{
        if(burgerMenu == true)
        {
            document.body.style.overflow = "hidden";
        }
        else 
        {
            document.body.style.overflow = "scroll"
        }
    },[burgerMenu])

    const toggleMenu = ()=>{
        setBurgerMenu((prev)=>{
            return !prev
        })
    }

    
    return (
        <div ref={ref}>
        {alreadyVisible  == true ?  (
            <motion.div 
                style={{
                    backgroundColor: bgColor,
                    fontFamily: theme.fonts.title,
                }}
                className='navbar_model_2-container'
                variants={{ hidden, show }} initial="hidden" animate="show"
            >
                <div className='navbar_model_2-container-links'>
                    {
                        links.map((el)=>{
                            return(
                                <div className='navbar_model_2-container-links-item'>
                                    <a 
                                        style={{
                                            '--navbar_model_2_color_hover': theme.colors.color_10,
                                        }}
                                        href={el.toId}>{el.title}</a>
                                </div>
                            )
                        })
                    }
                    {
                        button?.item ? 
                        <button.item {...button.props}/>
                        :
                        <DefaultButton/>
                    }
                </div>
                <div className='navbar_model_2-mobile-burger'>
                    <div onClick={toggleMenu} className={`navbar_model_2-mobile-burger-menu ${burgerMenu == true ? 'navbar_model_2-mobile-burger-menu-open': ''}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div  
                    style={{
                        backgroundColor: bgColor,
                        fontFamily: theme.fonts.title,
                    }}
                    className={`navbar_model_2-mobile-content ${burgerMenu == true ? 'navbar_model_2-mobile-content-open':''}`}>
                    {
                        links.map((el)=>{
                            return(
                                <div 
                                    className='navbar_model_2-mobile-content-item'>
                                    <a 
                                        onClick={()=>setBurgerMenu(false)}
                                        href={el.toId}>{el.title}</a>
                                </div>  
                            )
                        })
                        
                    }
                    {
                        button?.item ? 
                        <button.item {...button.props}/>
                        :
                        <DefaultButton/>
                    }
                </div>
            </motion.div> 
        ):null}
        </div>
    )
}