import React,{useState, useEffect, useRef} from 'react';
import './coreStyle.css';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from "react-intersection-observer";
// import DefaultImage from './res/DefaultImage.jpg';
// import DefaultBackground from './res/DefaultBg.jpg';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultTheme } from '../../../Style/Theme';
import { useIntersection } from 'react-intersection-observer';
import { useOnScreen } from '../../../core/utils/useOnScreen';

export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic
    
    //resources
    const items = resources?.items !== undefined ? resources.items :
    [{
        title: 'Default Title',
        headline: 'Default Headline',
        desc: 'Default Description Lorem ',
        bg: defaultImage[0]
    },
    {
        title: 'Default Title',
        headline: 'Default Headline',
        desc: 'Default Description Lorem ',
        bg: defaultImage[1]
    },
    {
        title: 'Default Title',
        headline: 'Default Headline',
        desc: 'Default Description Lorem ',
        bg: defaultImage[2]
    }]

    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //style
    const titleStyles = customStyle?.title !== undefined ? customStyle.title:{
        color: '#5e646c',
        backgroundColor: '#ffffff80;',
        fontFamily: theme.fonts.title,
        fontSize: '1.7rem'
    }
    const headlineStyles = customStyle?.headline !== undefined ? customStyle.headline:{
        color: '#5e646c',
        backgroundColor: '#ffffff80;',
        fontFamily: theme.fonts.content,
        fontSize: '1.7rem',
    }
    const descStyle = customStyle?.desc !== undefined ? customStyle.desc:{
        fontFamily: theme.fonts.content
    }
    const parentBg = customStyle?.parentBg !== undefined ? customStyle.parentBg: {backgroundImage: `url('${defaultImage[0]}')`}
    const itemContainerStyle = customStyle?.itemContainer !== undefined ? customStyle.itemContainer:{ border :`1px solid ${theme.colors.color_10}`}
    const bgCardStyle = customStyle?.bgCard !== undefined ? customStyle.bgCard:{backgroundColor: 'rgba(0,0,0, 0.5)'}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    //atoms
    const button = atoms?.button !== undefined ? atoms.button : undefined;
    //<<---component logic--->>


    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    return (
        <div
            ref={ref}
            style={{
                minHeight: '40vh'
            }}
        >
        {alreadyVisible  == true ?  (
            <motion.div 
                className='layers_widget_1-container'
                style={{
                    // backgroundImage: `url(${defaultBgImage[0]})`,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    padding: '40px',
                    backgoundSize: 'cover',
                    ...parentBg
                }}
                variants={{ hidden, show }} initial="hidden" animate="show"
                >
                    {
                        items.map((el, index)=>{
                            return(
                                <FlipItem 
                                    key={index}
                                    data={el} 
                                    titleStyles={titleStyles}
                                    headlineStyles={headlineStyles}    
                                    descStyle={descStyle}
                                    bgColor={bgCardStyle}
                                    itemContainerStyle={itemContainerStyle}
                                    button={button}

                                />
                            )
                        })
                    }
            </motion.div> 
        ):null}
        
        </div>
        
    )
}

const FlipItem = ({data,titleStyles, headlineStyles,descStyle,bgColor,itemContainerStyle,button})=>{
    
    const StyledChild = styled.div`
        min-width: 350px;
        padding: 20px;
        width: 33.33%;
        height: 370px;
        perspective: 1000px; /* Remove this if you don't want the 3D effect */
    `

    return(
    <StyledChild 
        className="flip-card"
        style={{
            ...itemContainerStyle
        }}
        
        >
        <div className="flip-card-inner">
            <div className="flip-card-front">
                <div className='flip-card-front-absolute'>
                    <span 
                        className='flip-card-front-absolute-title'
                        style={titleStyles}
                        >{data.title}</span>
                    <span 
                        className='flip-card-front-absolute-desc'
                        style={headlineStyles}
                        >{data.desc}</span>
                </div>
                <img src={data.bg} alt="Avatar" />
            </div>

            <div
                style={bgColor}  
                className="flip-card-back">
                <div className="flip-card-back-header">
                    <span 
                        style={titleStyles}
                        className="flip-card-back-header-title">{data.title}</span>
                    <span
                        style={headlineStyles}
                        className="flip-card-back-header-desc">{data.headline}</span>
                </div>
                <div className="flip-card-back-content">
                    <span
                        style={descStyle}
                    >
                        {data.desc}
                    </span>
                    {
                        button?.item ? 
                        <button.item {...button.props}/>
                        :
                        <DefaultButton/>
                    }
                </div>
            </div>
        </div>
    </StyledChild>
    )
}
