import {core} from './morgana-soul/_moraga-config';
import {routerDomSpreadCore} from './morgana-soul/core/routerDomSpread';

function App() {
  return (
    routerDomSpreadCore(core)
  );
}

export default App;
