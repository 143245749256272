export class Theme {
    constructor()
    {
        this.colors = {}
        this.fonts = {}
    }
    initColors(color_60,color_30,color_10){
        this.colors = {
            color_60,
            color_30,
            color_10
        }
    }
    initFonts(title,content){
        this.fonts ={
            title,
            content,
        }
    }
    
}

export const defaultTheme = new Theme()
defaultTheme.initFonts('Helvetica','Verdana') 
defaultTheme.initColors('red','blue','green')


export const defaultImage = [
    "https://images.unsplash.com/photo-1462331940025-496dfbfc7564?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=611&q=80",
    "https://images.unsplash.com/photo-1598118538790-f04d31348896?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1152&q=80",
    "https://images.unsplash.com/photo-1515966306809-37aaff37a72f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=583&q=80"
]

export const defaultBgImage = [
    "https://images.unsplash.com/photo-1619410283995-43d9134e7656?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1509718443690-d8e2fb3474b7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    "https://images.unsplash.com/photo-1593720213428-28a5b9e94613?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"

]
export const defaultLogo = "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/NASA_logo.svg/2449px-NASA_logo.svg.png"

export const DefaultButton = ()=>{
    return <button>Default btn</button>
}

export const DefaultRevealAnimation = {
    hidden: {
      scale: 0,
    },
    show: {
      scale: 1,
      transition: {
        duration: 1,
        ease: 'easeOut',
      },
    },
};

