export const RevealAnimations = {
    fadeIn: {
      hidden: { opacity: 0 },
      show: { opacity: 1, transition: { duration: 1 } }
    },
    slideUp: {
      hidden: { y: 50, opacity: 0 },
      show: { y: 0, opacity: 1, transition: { duration: 1 } }
    },
    scale: {
      hidden: { scale: 0.5, opacity: 0 },
      show: { scale: 1, opacity: 1, transition: { duration: 1 } }
    },
    rotate: {
      hidden: { rotate: 90, opacity: 0 },
      show: { rotate: 0, opacity: 1, transition: { duration: 1 } }
    },
    skew: {
      hidden: { skewY: 10, opacity: 0 },
      show: { skewY: 0, opacity: 1, transition: { duration: 1 } }
    },
    slideLeft: {
      hidden: { x: 50, opacity: 0 },
      show: { x: 0, opacity: 1, transition: { duration: 1 } }
    },
    slideRight: {
      hidden: { x: -50, opacity: 0 },
      show: { x: 0, opacity: 1, transition: { duration: 1 } }
    },
    flip: {
      hidden: { rotateY: 90, opacity: 0 },
      show: { rotateY: 0, opacity: 1, transition: { duration: 1 } }
    },
    spring: {
      hidden: { scale: 0.5, opacity: 0 },
      show: {
        scale: 1,
        opacity: 1,
        transition: { type: "spring", stiffness: 150, damping: 15 }
      }
    },
    keyframes: {
      hidden: { opacity: 0, scale: 0.5, rotate: 0 },
      show: {
        opacity: 1,
        scale: 1,
        rotate: 360,
        transition: {
          duration: 1,
          ease: "easeInOut",
          times: [0, 0.2, 0.4, 0.6, 0.8, 1],
          loop: Infinity,
          repeatDelay: 1
        }
      }
    },
    pulse: {
      hidden: { scale: 0.8, opacity: 0.5 },
      show: {
        scale: 1,
        opacity: 1,
        transition: { duration: 1, ease: "easeInOut", yoyo: Infinity }
      }
    },
    bounce: {
      hidden: { y: -100 },
      show: {
        y: 0,
        transition: { type: "spring", stiffness: 120, damping: 15, duration: 1 }
      }
    },
  
    scaleIn: {
      hidden: { scale: 0, opacity: 0 },
      show: {
        scale: 1,
        opacity: 1,
        transition: { duration: 0.8, ease: "easeOut" }
      }
    },
    skewIn: {
      hidden: { skewX: "-40deg", opacity: 0 },
      show: {
        skewX: "0deg",
        opacity: 1,
        transition: { duration: 1, ease: "easeInOut" }
      }
    },
    rotateIn: {
      hidden: { rotate: -180, opacity: 0 },
      show: {
        rotate: 0,
        opacity: 1,
        transition: { duration: 1.2, ease: "backOut" }
      }
    },
    wobble: {
      hidden: { x: -50, rotate: -10, opacity: 0 },
      show: {
        x: 0,
        rotate: 0,
        opacity: 1,
        transition: { duration: 1, ease: "easeOut" }
      }
    },
    zoomIn: {
      hidden: { scale: 0.2, opacity: 0 },
      show: {
        scale: 1,
        opacity: 1,
        transition: { duration: 0.8, ease: "backOut" }
      }
    },
    fadeInOut: {
      hidden: { opacity: 0 },
      show: {
        opacity: 1,
        transition: { duration: 1.2, yoyo: Infinity, ease: "easeInOut" }
      }
    },
    shake: {
      hidden: { x: -20 },
      show: {
        x: [0, -20, 20, -20, 20, -20, 20, -20, 20, 0],
        transition: { duration: 1.2 }
      }
    },
    rotateScale: {
      hidden: { rotate: 90, scale: 0, opacity: 0 },
      show: {
        rotate: 0,
        scale: 1,
        opacity: 1,
        transition: { duration: 1.2, ease: "easeOut" }
      }
    },
    rotateZoom: {
      hidden: { rotate: 180, scale: 0, opacity: 0 },
      show: {
        rotate: 0,
        scale: 1,
        opacity: 1,
        transition: { duration: 1.2, ease: "easeInOut" }
      }
    }
  };