import React,{useState, useEffect} from 'react';
import './coreStyle.css';
import DefaultPhoneImg from './res/phone.png';
import DefaultSocialFb from './res/social-media.png';
import DefaultSocialIg from './res/instagram.png';
import DefaultSocialYt from './res/youtube.png';

import { defaultBgImage, defaultImage, defaultLogo } from '../../../Style/Theme';
import { defaultTheme } from '../../../Style/Theme';
export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{

    //logic

    //resources
    const defaultIcon = resources?.defaultIcon ? resources.defaultIcon: defaultLogo;
    const icons = resources?.icons ? resources.icons :[
        {
            icon: defaultLogo,
            link: 'https://www.google.com'
        },
        {
            icon: defaultLogo,
            link: 'https://www.google.com'
        },
        {
            icon: defaultLogo,
            link: 'https://www.google.com'
        }]
    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyles
    const border = customStyle?.border ? customStyle.border: `1px solid ${theme.colors.color_10}`;
    const bgColor = customStyle?.bgColor ? customStyle.bgColor:theme.colors.color_60

    const redirectCallback = (dest)=>{
        window.location = dest
    }
    return (
        <div className='layers_stickymobile_1-container'>
            <div 
                className='layers_stickymobile_1-container-social'
                style={{
                    border: border,
                    backgroundColor: bgColor
                }}
                >
                <img className='layers_stickymobile_1-container-social-default' src={defaultIcon} />
                {
                    icons.map((el)=>{
                        return <img className='layers_stickymobile_1-container-social-elem' src={el.icon}  onClick={()=>redirectCallback(el.link)}/>
                    })
                }
            </div>
        </div>
    )
}
