import React,{useState, useEffect, useRef} from 'react';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../Style/Theme';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../core/utils/useOnScreen';

import './coreStyle.css';

export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic
    const id = logic?.id ? logic.id : 'widget-8-id'

    //resources
    const items = resources?.items ? resources.items : [{name: "Default Name", img: defaultImage[0]}]
    const special = resources?.special ? resources.special : {
        left: 'Left text',
        img: defaultLogo,
        right: 'Right text'
    }
    const decor = resources?.decor ? resources.decor: defaultImage[0];
    // const decor2 = resources?.decor2 ? resources.decor2: defaultImage[1];
    
    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage[1]}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    // atoms
    const title = atoms?.title !== undefined ? atoms.title : undefined;

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    return(
        <div
            id={id}
            ref={ref}
            style={{
                minHeight: '40vh',
            }}
        >
                {alreadyVisible  == true ?  (
            <motion.div 
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_widget_8-container'
                style={{
                    fontFamily: theme.fonts.title,
                    ...bg
                }}
                >
                    {
                        title?.item ? 
                        <title.item {...title.props}/>
                        :
                        <p>Default title</p>
                    }
                    <div className='layers_widget_8-container-content'>
                        {items.map((el)=>{
                            return(<Item title={el.name} decor={decor} img={el.img}/>)
                        })}
                    </div>
                    <div className='layers_widget_8-container-bot'>
                        <div className='layers_widget_8-container-bot-wrap'>
                            <span>{special.left}</span>
                            <img
                                style={{
                                    transform: 'rotate(270deg)'
                                }} 
                                src={decor} alt="decor"/>
                        </div>
                        <div className='layers_widget_8-container-bot-center'>
                            <img src={special.img} alt="item"/>
                        </div>
                        <div className='layers_widget_8-container-bot-wrap'>
                            <span>{special.right}</span>
                            <img
                                style={{
                                    transform: 'rotate(90deg)'
                                }} 
                                src={decor} alt="decor"/>
                        </div>
                    </div>
                </motion.div>):null}
        </div>
    )
}


const Item = ({title, decor, img})=>{
    return(
        <div className='layers_widget_8-container-content-item'>
            <div className='layers_widget_8-container-content-item-title'>
                <span>{title}</span>
            </div>
            <div className='layers_widget_8-container-content-item-decor'>
                <img src={decor} alt="decor"/>
            </div>
            <div className='layers_widget_8-container-content-item-img'>
                <img src={img} alt="item"/>
            </div>

        </div>
    )
}