import React,{useState, useEffect, useRef} from 'react';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../Style/Theme';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../core/utils/useOnScreen';
import './coreStyle.css';


export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic
     //logic
     const id = logic?.id ? logic.id : 'widget-13-id';
    //resources
    const img = resources?.img ? resources.img : defaultImage;
    const desc = resources?.desc ? resources.desc : "Default desc";

    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage[1]}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    // atoms
    const title = atoms?.title !== undefined ? atoms.title : undefined;
    const button = atoms?.button !== undefined ? atoms.button : undefined;

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    return(
        <div
            ref={ref}
            style={{
                minHeight: '40vh',
            }}
        >
                {alreadyVisible  == true ?  (
            <motion.div 
                id={id}
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_widget_13-container'
                style={{
                    fontFamily: theme.fonts.title,
                    ...bg
                }}
                >
                {
                    title?.item ? 
                    <title.item {...title.props}/>
                    :
                    <p>Default title</p>
                }
                <div className='layers_widget_13-container-items'>
                    <div className='layers_widget_13-container-items-left'>
                        <img src={img} alt="decor"/>
                    </div>
                    <div className='layers_widget_13-container-items-right'>
                        <span>{desc}</span>
                        <br/>
                        <br/>
                        <span>Ramânem digitali cu Livresq, Nabu si ETWINNING, căci viitorul nostru este legat de noile tehnologii.</span>
                    </div>

                </div>
                    
                </motion.div>):null}
        </div>
    )
}

const Item = ({name, icon, theme})=>{
    return(
        <div className='layers_widget_12-container-left-contact'>
            <img src={icon}/>
            <span>{name}</span>
        </div>
    )
}