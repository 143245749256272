import React, { useEffect } from 'react';
import { Omnitrix } from '../Modules/modulesHandler';

export default function DevLibrary (){
    const testFormat = ()=>{
        const omnitrixComponents = [];
        for (const key in Omnitrix) {
            const Component = Omnitrix[key].item;
            if(Omnitrix[key].dev == true)
            {
                omnitrixComponents.push([<Component key={key} />, key]);
            }
          }
          console.log("omnitrixComponents:",omnitrixComponents)
          return omnitrixComponents
    }
    return (
        <>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px',
            fontFamily: 'Arial'
        }}>
            <span>Morgana Core Dev Mode</span>
        </div>
        {
            testFormat().map((el)=>{
                return(
                    <>
                        <Separator content={el[1]}/>
                        {el[0]}
                    </>
                )
            })
        }
        </>
    )
}

const Separator = ({content})=>{
    return(
        <div style={{
            borderBottom: '1px solid black',
            padding: '20px'
        }}>
            {content}
        </div>
    )
}