//atoms
import Atom_Buttons_Btn_1 from './Atoms/Buttons/Button_1/coreModule';
import Atom_Title_1 from './Atoms/Titles/Title_1/coreModule';

//import modules
import Module_Wrappers_Navbar_1 from './Wrappers/Navbar/Model_1/coreModule';
import Module_Wrappers_Navbar_2 from './Wrappers/Navbar/Model_2/coreModule';


import Module_Layers_Widget_1 from './Layers/Widget_1/coreModule';
import Module_Layers_Widget_2 from './Layers/Widget_2/coreModule';
import Module_Layers_Widget_3 from './Layers/Widget_3/coreModule';
import Module_Layers_Widget_5 from './Layers/Widget_5/coreModule';
import Module_Layers_Widget_6 from './Layers/Widget_6/coreModule';
import Module_Layers_widget_7 from './Layers/Widget_7/coreModule';
import Module_Layers_widget_8 from './Layers/Widget_8/coreModule';
import Module_Layers_widget_9 from './Layers/Widget_9/coreModule';
import Module_Layers_widget_10 from './Layers/Widget_10/coreModule';
import Module_Layers_widget_11 from './Layers/Widget_11/coreModule';
import Module_Layers_widget_12 from './Layers/Widget_12/coreModule';
import Module_Layers_widget_13 from './Layers/Widget_13/coreModule';
import Module_Layers_widget_14 from './Layers/Widget_14/coreModule';

import Module_Wrappers_Footer_1 from './Wrappers/Footer/Footer_1/coreModule';


export const Omnitrix = {
    // --atoms--
    'Atom_Buttons_Btn_1': {item: Atom_Buttons_Btn_1, dev: true},
    'Atom_Title_1': {item: Atom_Title_1, dev: true},
    // --wrappers(navbar)-- 
    'Module_Wrappers_Navbar_1': {item: Module_Wrappers_Navbar_1, dev: false}, //dev false  bcs it has absolute top 0;
    'Module_Wrappers_Navbar_2': {item: Module_Wrappers_Navbar_2, dev: true},

    // --modules--
    'Module_Layers_Widget_1': {item: Module_Layers_Widget_1, dev: true},
    'Module_Layers_Widget_2': {item: Module_Layers_Widget_2, dev: false}, //dev false bcs it has position sticky on the screen
    'Module_Layers_Widget_3': {item: Module_Layers_Widget_3, dev: true},
    'Module_Layers_Widget_5': {item: Module_Layers_Widget_5, dev: true},
    'Module_Layers_Widget_6': {item: Module_Layers_Widget_6, dev: true},
    'Module_Layers_widget_7': {item: Module_Layers_widget_7, dev: true},
    'Module_Layers_widget_8': {item: Module_Layers_widget_8, dev: true},
    'Module_Layers_widget_9': {item: Module_Layers_widget_9, dev: true},
    'Module_Layers_widget_10': {item: Module_Layers_widget_10, dev: true},
    'Module_Layers_widget_11': {item: Module_Layers_widget_11, dev: true},
    'Module_Layers_widget_12': {item: Module_Layers_widget_12, dev: true},
    'Module_Layers_widget_13': {item: Module_Layers_widget_13, dev: true},
    'Module_Layers_widget_14': {item: Module_Layers_widget_14, dev: true},
    // --wrappers(footer)-- 
    'Module_Wrappers_Footer_1': {item: Module_Wrappers_Footer_1, dev: true},
}