import React,{useState, useEffect, useRef} from 'react';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../Style/Theme';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../core/utils/useOnScreen';
import './coreStyle.css';


export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic
     //logic
     const id = logic?.id ? logic.id : 'widget-13-id';
    //resources
    const items = resources?.items ? resources.items : ['aaa','bb']
    const checkImg = resources?.checkImg ? resources.checkImg : defaultLogo;

    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage[1]}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    // atoms
    const title = atoms?.title !== undefined ? atoms.title : undefined;

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    function obtinePrimaJumatate(array) {
        var jumatate = Math.ceil(array.length / 2); // Obține lungimea jumătății
        var primaJumatate = array.slice(0, jumatate); // Obține subsecvența primei jumătăți
        return primaJumatate;
      }
      
      function obtineADouaJumatate(array) {
        var jumatate = Math.ceil(array.length / 2); // Obține lungimea jumătății
        var aDouaJumatate = array.slice(jumatate); // Obține subsecvența celei de-a doua jumătăți
        return aDouaJumatate;
      }

    return(
        <div
            ref={ref}
            style={{
                minHeight: '40vh',
            }}
        >
                {alreadyVisible  == true ?  (
            <motion.div 
                id={id}
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_widget_14-container'
                style={{
                    fontFamily: theme.fonts.title,
                    ...bg
                }}
                >
                {
                    title?.item ? 
                    <title.item {...title.props}/>
                    :
                    <p>Default title</p>
                }
                <div className='layers_widget_14-container-items'>
                    <div className='layers_widget_14-container-items-column'>
                        {
                            obtinePrimaJumatate(items).map((el)=>{
                                return <Item img={checkImg} text={el}/>
                            })
                        }
                    </div>
                    <div className='layers_widget_14-container-items-column'>
                        {
                            obtineADouaJumatate(items).map((el)=>{
                                return <Item img={checkImg} text={el}/>
                            })
                        }
                    </div>
                </div>
                    
                </motion.div>):null}
        </div>
    )
}
const Item = ({img, text})=>{
    return(
        <div className='layers_widget_14-container-items-column-item'>
            <img src={img} />
            <span>{text}</span>
        </div>
    )
}