import {useState, useEffect} from 'react';
import './coreStyle.css';
import MapPinIconDefault from './res/maps-and-flags.png';
import PhoneIconDefault from './res/phone-call.png';
import LogoTemplate from './res/logo-template.png';
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { Link} from "react-router-dom";
import { defaultLogo, defaultTheme } from '../../../../Style/Theme';

export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam, //this navbar don't support revealAnimation
    atoms, //this navbar don't support atoms
})
{
    //resources
    const mapIcon = resources?.mapIcon ? resources.mapIcon : MapPinIconDefault
    const locationName = resources?.locationName ? resources.locationName : "Paraguai"
    const phoneIcon = resources?.phoneIcon ? resources.phoneIcon : PhoneIconDefault
    const phoneName = resources?.phoneName ? resources.phoneName : "+40 234 234 234"
    const logo = resources?.logo ? resources.logo : defaultLogo
    const links = resources?.links ? resources.links : {
        left:[
            {
                name: 'page_1',
                ref: '/page_1'
            },
            
            {
                name: 'page_2',
                ref: '/page_2'
            },
          
        ],
        right:[
            {
                name: 'page_3',
                ref: '/page_3'
            },
            
            {
                name: 'page_4',
                ref: '/page_4'
            }
        ]
    }

    //theme
    const theme = themeParam ? themeParam: defaultTheme;

    //customStyles
    // const iconsColor = customStyle?.iconsColor ? customStyle.iconsColor : theme.colors.color_10
    const primaryFont = customStyle?.primaryFont ? customStyle.primaryFont : theme.fonts.content
    const secondaryFont = customStyle?.secondaryFont ? customStyle.secondaryFont : theme.fonts.content
    const fontColor = customStyle?.fontColor ? customStyle.fontColor : 'white'
    const fontColorOnScroll = customStyle?.fontColorOnScroll ? customStyle.fontColorOnScroll : theme.colors.color_30
    const bgFirstColor = customStyle?.bgFirstColor ? customStyle.bgFirstColor : 'rgba(0, 0, 0, 0.7)'
    const bgColorOnScroll = customStyle?.bgColorOnScroll ? customStyle.bgColorOnScroll : theme.colors.color_60
    const mobileFontSize = customStyle?.mobileFontSize ? customStyle.mobileFontSize : '1.5rem'
    const borderItemsHover = customStyle?.borderItemsHover ? customStyle.borderItemsHover : theme.colors.color_10
    const hoverTextColor = customStyle?.hoverTextColor ? customStyle.hoverTextColor : theme.colors.color_10
    const hoverAnim = customStyle?.hoverAnim ? customStyle.hoverAnim : 1
    const borderBottomColor = customStyle?.borderBottomColor ? customStyle.borderBottomColor : theme.colors.color_10
    const containerPadding = customStyle?.containerPadding ? customStyle.containerPadding : ''
    const logoHeight = customStyle?.logoHeight ? customStyle.logoHeight : ''

    //<<---component logic--->>
    const location = useLocation();
    const navigate = useNavigate();
    const [burgetActive, setBurgerActive] = useState(false);
    const [bgColorState, setBgColorState] = useState(bgFirstColor);

    const internStyle={
        btnLink:{
            color: fontColor?(bgColorState==bgFirstColor ? fontColor:fontColorOnScroll):'white'
        },
        btnMobileLink:{
            color: fontColor?(bgColorState==bgFirstColor ? fontColor:fontColorOnScroll):'white',
            transition:'all 0.5s ease',
            width: burgetActive ?'auto':'0px',
            padding:burgetActive ? '20px' :'0px',
            fontSize:'2.5rem',
            textDecoration: 'none',
            overflow:'hidden',
            fontSize: mobileFontSize?mobileFontSize:'1.5rem'
        },
        phoneLocationSpan:{
            fontFamily: secondaryFont?secondaryFont:'none',
            color: fontColor?(bgColorState==bgFirstColor ? fontColor:fontColorOnScroll):'white'
        }

    }

    useEffect(()=>{
        setBurgerActive(false);
    },[location])
    useEffect(()=>{
        let checkTrigger = ()=>{
            let trigger = 100
            
            if(window.scrollY > trigger)
            {
                setBgColorState(bgColorOnScroll)
            }
            else if(window.scrollY < trigger) 
            {
                setBgColorState(bgFirstColor)
            }
        }
        let bgTrigger = window.addEventListener('scroll', checkTrigger);

        return window.removeEventListener('scroll',bgTrigger)

    },[])
    const decideBeforeAnimation = ()=>{
        switch(hoverAnim)
        {
            case 1:
                return 'navbar_model_1_anim_1'
            case 2:
                return 'navbar_model_1_anim_2'
            case 3:
                return 'navbar_model_1_anim_3'
            case 4:
                return 'navbar_model_1_anim_4'
            case 5:
                return 'navbar_model_1_anim_5'
            case 6:
                return 'navbar_model_1_anim_6'
            case 7:
                return 'navbar_model_1_anim_7'
            case 8:
                return 'navbar_model_1_anim_8'
            default: 
                return 'navbar_model_1_anim_1'
        }
    }
    const homePageRedirect = ()=>{
        navigate('/');
    }

    return(
        <>
        <div className='navbar_model_1-container navbar_model_1-underline-option ' 
            style={{ 
                backgroundColor: bgColorState,
                '--navbar_model_1-before-color': borderItemsHover,
                '--navbar_model_1-link-text-color': fontColor?(bgColorState==bgFirstColor ? fontColor:fontColorOnScroll):'white',
                '--navbar_model_1-hover-text-color': hoverTextColor,
                borderBottom: borderBottomColor?`2px solid ${borderBottomColor}`:'none',
                padding: containerPadding ?containerPadding:'none'
                }}>
            <div className='navbar_model_1-container-location-and-phone'>
                <img src={mapIcon ? mapIcon : MapPinIconDefault}  />
                <span style={internStyle.phoneLocationSpan} >{locationName}</span>
            </div>
            <div className='navbar_model_1-container-center'
                 style={{
                    fontFamily: primaryFont ? primaryFont:'none',
                 }}
             >
                <div className={`navbar_model_1-container-center-link-container ${decideBeforeAnimation()}`}>
                    {
                        links?.left.map((el)=>{
                            return(
                                <Link to={el.ref}>{el.name}</Link>
                            )
                        })
                    }
                </div>
                <div className='navbar_model_1-container-center-logo'>
                    <img onClick={homePageRedirect} src={logo} alt="logo" style={{height: logoHeight ? logoHeight :'60px'}}/>
                </div>
                <div className={`navbar_model_1-container-center-link-container ${decideBeforeAnimation()}`}>
                    {
                        links?.right.map((el)=>{
                            return(
                                <Link to={el.ref}>{el.name}</Link>
                            )
                        })
                    }
                </div>
            </div>
            <div className='navbar_model_1-container-location-and-phone'>
                <img src={phoneIcon ? phoneIcon : PhoneIconDefault}  />
                <span style={internStyle.phoneLocationSpan} >{phoneName}</span>
            </div>
        </div>
        <div className='navbar_model_1-container-mobile' 
             style={{
                    backgroundColor: bgColorState,
                    borderBottom: borderBottomColor? `2px solid ${borderBottomColor}`:'none'
                    }}>
            <div className='navbar_model_1-container-mobile-logo'>
                <img
                    onClick={homePageRedirect}
                    src={logo} alt="logo" style={{height: logoHeight ? logoHeight :'60px'}}/>
            </div>
            <div 
                onClick={()=>setBurgerActive((prev)=>!prev)} 
                className={`navbar_model_1-container-mobile-burger ${burgetActive ? 'navbar_model_1-container-mobile-burger-active':''}`}>
                <span id="navbar_model_1_burger_1"></span>
                <span id="navbar_model_1_burger_2"></span>
                <span id="navbar_model_1_burger_3"></span>
            </div>

            <div 
                style={{
                    backgroundColor: bgColorState,
                    fontFamily: primaryFont ? primaryFont:'none',
                }}
                className={`navbar_model_1-container-mobile-absolute-container ${burgetActive ? 'navbar_model_1-container-mobile-absolute-container-active':''}`}>
                <div className='navbar_model_1-container-mobile-absolute-container-top'>
                {
                    links?.left.map((item)=>{
                        return(
                            <div className='navbar_model_1-container-mobile-absolute-container-item'>
                                <Link to={item.ref} style={{...internStyle.btnMobileLink }}>{item.name}</Link>
                            </div>
                        )
                    })
                }
                {
                    links?.right.map((item)=>{
                        return(
                            <div className='navbar_model_1-container-mobile-absolute-container-item'>
                                <Link to={item.ref} style={{...internStyle.btnMobileLink }}>{item.name}</Link>
                            </div>
                        )
                    })
                }
                </div>
                <div className='navbar_model_1-container-mobile-absolute-container-bot'>
                    <img src={phoneIcon ? phoneIcon : PhoneIconDefault}/>
                    <span style={internStyle.phoneLocationSpan} >{phoneName}</span>
                </div>
            </div>
        </div>
        </>
    )
}