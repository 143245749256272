import {useState, useEffect, useRef} from 'react';
import { useInView } from "react-intersection-observer";
import './coreStyle.css';
import { defaultTheme,DefaultRevealAnimation } from '../../../../Style/Theme';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../../core/utils/useOnScreen';

//https://codepen.io/rafaaro/pen/oNvJQyb   

export default function CoreModule({
    logic, 
    resources, 
    themeParam, 
    customStyle, //only for atoms, widgets have style props in resources
    revealAnimationParam, 
    //atoms -atoms can't contain atoms
})
{
    //logic
    const onClickParam = logic?.onClick ? logic.onClick : ()=>{};

    //resources
    const {text} = resources ? resources :
    {
        text: 'Default Text'
    }

    //theme
    const theme = themeParam ? themeParam : defaultTheme;

    //revealAnimation (!! atoms have a single reveal animation, widgets have more)
    const revealAnimation = revealAnimationParam ? revealAnimationParam : DefaultRevealAnimation;

    // customStyle
    const buttonStyle ={
      backgroundColor: theme.colors.color_10,
      color: theme.colors.color_30,
      padding: '10px 20px',
      outline: 'none',
      border: 'none',
      fontFamily: theme.fonts.title,

    }


    const { hidden, show } =  revealAnimation

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])
  
    return (
      <div
        ref={ref}
        style={{
          //component must have fixed height for the reveal animation to run
          minHeight: '10px',
          width: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {alreadyVisible == true ? (
          <motion.button 
            onClick={onClickParam}
            variants={{ hidden, show }} 
            initial="hidden" 
            animate="show"
            style={{
                ...buttonStyle,
                ...customStyle
            }}
            className='atoms_buttons_btn_1'
            >
            {text}
          </motion.button>
        ):null}
      </div>
    );
}