import React,{useState, useEffect, useRef} from 'react';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../Style/Theme';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../core/utils/useOnScreen';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

import './coreStyle.css';
const defaultItems = [
    {
        img: defaultImage[1],
        title: 'Default Text 1'
    },
    {
        img: defaultImage[1],
        title: 'Default Text 1'
    },
    {
        img: defaultImage[1],
        title: 'Default Text 1'
    },
]
export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic
    const id = logic?.id ? logic.id : 'widget-9-id';

    //resources
    const decor = resources?.decor ? resources.decor: defaultImage[0]; //sabie 
    const items = resources?.items ? resources.items : defaultItems;

    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage[1]}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    // atoms
    const title = atoms?.title !== undefined ? atoms.title : undefined;

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    return(
        <div
            id={id}
            ref={ref}
            style={{
                minHeight: '40vh',
            }}
        >
                {alreadyVisible  == true ?  (
            <motion.div 
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_widget_9-container'
                style={{
                    fontFamily: theme.fonts.title,
                    ...bg
                }}
                >
                     {
                        title?.item ? 
                        <title.item {...title.props}/>
                        :
                        <p>Default title</p>
                    }
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '20px',
                        marginTop: '60px',
                    }}>
                        {
                            resources.specialItems.map((el)=>{
                                return <SpecialItem link={el.link} title={el.title} img={el.img} decor={decor} theme={theme}/>
                            })
                        }
                    </div>
                    <div className='layers_widget_9-container-items'>
                        {
                            items.map((el)=>{
                                return <Item title={el.title} img={el.img} decor={decor} theme={theme}/>
                                // if(el.title == 'Grupul de excelență mate-olimpiadă')
                                // {
                                //     return (<SpecialItem title={el.title} img={el.img} decor={decor} theme={theme}/>)
                                // }
                                // else if(el.title == 'Grupul de excelență șah -olimpiada-')
                                // {
                                //     return (<SpecialItem title={el.title} img={el.img} decor={decor} theme={theme}/>)
                                // }
                                // else 
                                // {
                                //     return ()
                                // }
                            })
                        }
                        {/* { 
                            img: imagesHarap[37],
                            title: 'Ora de mate -olimpiada-'
                        }, */}

                    </div>
                   
                </motion.div>):null}
        </div>
    )
}


const Item = ({title, img, decor,theme})=>{
    return(
       <div className='layers_widget_9-container-items-el'>
        <div className='layers_widget_9-container-items-el-img'>
            <img src={img} alt="activity"/>
        </div>
        <div className='layers_widget_9-container-items-el-decor'>
            <img src={decor} alt="decor"/>
        </div>
        <div
            style={{
                fontFamily: theme.fonts.title,
                color: 'white',
                backgroundColor: theme.colors.color_30
            }} 
            className='layers_widget_9-container-items-el-title'
            >
                <span>{title.toUpperCase()}</span>

        </div>

       </div>
    )
}

const SpecialItem = ({link, title, img, decor,theme})=>{

    const [open, setOpen] = useState(false);
    useEffect(()=>{
        console.log("open ipdate:",open)
    },[open])
    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        console.log("cevA:",open)
        setOpen(false)
    };
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '60vh',
        boxShadow: 24,
        padding: '10px',
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        backgroundColor: 'white',
      };

      const handleRedirect = ()=>{
        window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeUyno-B8XPwuIKwz8mti8qEugKGuyPX1STiShvkPGAdQt7Uw/viewform'
    }
    const handleFormRedirect = ()=>{
        window.location = link
    }
    return(
       <div 
            className='layers_widget_9-container-items-el'
        >
            <div className='layers_widget_9-container-items-el-img'>
                <img src={img} alt="activity"/>
            </div>
            <div className='layers_widget_9-container-items-el-decor'>
                <img src={decor} alt="decor"/>
            </div>
            <div
                style={{
                    fontFamily: theme.fonts.title,
                    color: 'white',
                    backgroundColor: theme.colors.color_30,
                    borderBottomLeftRadius: '0px'
                }} 
                className='layers_widget_9-container-items-el-title'
                >
                    {/* <span>{title.toUpperCase()}</span> */}
                    <span>{title}</span>

            </div>
            <div
                onClick={handleFormRedirect}
                className='layers_widget_9-container-items-el-title-special'>
                <span>REZERVĂ LOC</span>
                
            </div>
       </div>
    )
}
