import React,{useState, useEffect, useRef} from 'react';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../../Style/Theme';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../../core/utils/useOnScreen';
import './coreStyle.css';
import ArrowIcon from './res/arrow.png';

const defaultRefs = [
    {
        name: 'Default',
        link: '#acasa'
    },
]
const defaultSocial = [
    {
        name: 'default social',
        link: 'https://www.google.com'
    },
]
export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic

    //resources
    const logo = resources?.logo ? resources.logo:defaultLogo;
    const title = resources?.title ? resources.title:'Default title';
    const phone = resources?.phone ? resources.phone:'+234 234 234';
    const mail = resources?.mail ? resources.mail: 'defaultmail@gmail.com';
    const refs = resources?.refs ? resources.refs:defaultRefs;
    const social = resources?.social ? resources.social:defaultSocial;
    const copyrights = resources?.copyrights ? resources.copyrights:'defalt copyrights';
    
    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage[1]}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    // atoms

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    const formatFirstLetter = (source)=>{
        console.log("title:",title)
        return (
          <>
           <span 
                style={{
                fontFamily: theme.fonts.content,
                fontWeight: 'bold',
                fontSize: '3rem',
                paddding: '10px',
              }}>{source[0]}</span>
            <span 
                style={{
                fontFamily: theme.fonts.title
              }}><i>{source.substring(1)} </i></span>
            
          </>
        )
      }


    return(
        <div
            ref={ref}
            style={{
                minHeight: '50vh',
            }}
        >
                {alreadyVisible  == true ?  (
            <motion.div 
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_footer_1-container'
                style={{
                    fontFamily: theme.fonts.title,
                    ...bg
                }}
                >
                    <div 
                        style={{
                            color: theme.colors.color_60,
                        }}
                        className='layers_footer_1-container-left'>
                        <div className='layers_footer_1-container-left-logo'>
                            <img src={logo} alt="logo"/>
                        </div>
                        <div className='layers_footer_1-container-left-content'>
                            <div className='layers_footer_1-container-left-content-top'>
                                <span>{formatFirstLetter(title)}</span>
                            </div>
                            <div className='layers_footer_1-container-left-content-bot'>
                                <p>{phone}</p>
                                <p>{mail}</p>
                            </div>
                        </div>
                    </div>
                    <div className='layers_footer_1-container-right'>
                    <div className='layers_footer_1-container-right-column'>
                        {
                            refs.map((el)=>{
                                return(
                                    <div className='layers_footer_1-container-right-column-ref'>
                                        <a 
                                            href={el.link}>
                                                {el.name}
                                            </a>
                                    </div>

                                )
                            })
                        }
                    </div>
                    <div className='layers_footer_1-container-right-column'>
                    {
                            social.map((el)=>{
                                return(
                                    <div className='layers_footer_1-container-right-column-ref'>
                                        <a 
                                            href={el.link}>
                                                {el.name}
                                            </a>
                                    </div>

                                )
                            })
                        }
                    </div>
                    <div className='layers_footer_1-container-right-column'>
                        <div 
                            style={{
                                backgroundColor: theme.colors.color_10
                            }}
                            className='layers_footer_1-container-right-column-scroll'>
                            <img 
                                onClick={()=>{window.scrollTo(0, 0)}}
                                src={ArrowIcon} alt="arrow"/>
                        </div>
                    </div>
                    </div>
                    
                    
                </motion.div>):null}
        </div>
    )
}
