
import {useState, useEffect} from 'react';

export function useOnScreen(ref, threshold = 0.8) {
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
        setIsVisible(entry.intersectionRatio >= threshold);
        },
        { threshold }
    );
  
    if (ref.current) {
        observer.observe(ref.current);
    }
  
    return () => {
        observer.unobserve(ref.current);
    };
    }, [ref, threshold]);
  
    return isVisible;
}