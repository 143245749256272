import React,{useState, useEffect,useRef} from 'react';
import { useOnScreen } from '../../../core/utils/useOnScreen';
import './coreStyle.css';
import { motion } from 'framer-motion';
import Carousel from "react-elastic-carousel";
import { defaultBgImage } from '../../../Style/Theme';
import { defaultTheme } from '../../../Style/Theme';
import { DefaultRevealAnimation } from '../../../Style/Theme';
// npm i react-elastic-carousel
// npm i styled-components

export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic

    //resources
    const pictures = resources?.pictures ? resources.pictures: [...Array(5).keys()].map((el)=>defaultBgImage[0])
    
    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const parent = customStyle?.parent ? customStyle.parent : {backgroundColor: theme.colors.color_30}
    const buttonsColor = customStyle?.buttonsColor ? customStyle.buttonsColor : theme.colors.color_10
    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;
    
    //<<---component logic--->>
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 2 },
        { width: 768, itemsToShow: 3 },
        { width: 1200, itemsToShow: 4 }
    ];

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    return (
        <div 
            ref={ref}
            className='layers_widget_3-container'
            style={{
                minHeight: '100px',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                '--widget_3_btn_overwrite': buttonsColor,
                ...parent
            }}
            >
                {
                    alreadyVisible == true ?(
                        <motion.div
                            variants={{ hidden, show }} initial="hidden" animate="show">
                                 
                <Carousel breakPoints={breakPoints}>
                    {
                        pictures.map((el)=>{
                            return(
                                <Item imageParam={el}>1</Item>
                            )
                        })
                    }
                </Carousel>
                            </motion.div>

                    ):null
                }
               
        </div>
    )
}


const Item = ({imageParam})=>{
    return(
        <div 
            className='layers_widget_3-item'
            style={{
                backgroundImage: `url('${imageParam}')`
            }}
            >
        </div>
    )
}