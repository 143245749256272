import { Theme, defaultBgImage, defaultImage, defaultLogo } from "./Style/Theme";
import { Omnitrix } from "./Modules/modulesHandler";
import { RevealAnimations } from "./Style/RevealAnimations";
import DashboardComponent from "./core/DashboardComponent/DashboardComponent";

const theme = new Theme();
theme.initFonts(`'Faustina'`, `'Monsieur La Doulaise'`); // <!!!> custom fonts needs to be imported in index.css
theme.initColors("#ffffff", "#013401", "#e59544");

const imagesHarap = [
  "./HarapAlb/logo.svg",
  "./HarapAlb/decor_1.svg",
  "./HarapAlb/firma_1.png",
  "./HarapAlb/firma_2.png",
  "./HarapAlb/firma_3.png",
  "./HarapAlb/firma_4.png",
  "./HarapAlb/firma_5.png",
  "./HarapAlb/firma_6.png",
  "./HarapAlb/sabie_1.svg",
  "./HarapAlb/bg_hero.png", //index 9
  "./HarapAlb/Hero/hero_1.png", //index 10
  "./HarapAlb/sword_1.png", //index 11
  "./HarapAlb/child_1.png", //index 12
  "./HarapAlb/bg_custom.png", //index 13
  "./HarapAlb/Activitati/1.png", //index 14
  "./HarapAlb/Activitati/2.png", //index 15
  "./HarapAlb/Activitati/3.png", //16
  "./HarapAlb/Activitati/4.png", //17
  "./HarapAlb/Activitati/5.png", //index 18
  "./HarapAlb/decor_2.svg", //index 19

  "./HarapAlb/Galerie/1.png", //index 20
  "./HarapAlb/Galerie/2.png",
  "./HarapAlb/Galerie/3.png",
  "./HarapAlb/Galerie/4.png",
  "./HarapAlb/Galerie/5.png",
  "./HarapAlb/Galerie/6.png",
  "./HarapAlb/Galerie/7.png",
  "./HarapAlb/Galerie/8.png",
  "./HarapAlb/Galerie/9.png",
  "./HarapAlb/Galerie/10.png",
  "./HarapAlb/Galerie/11.png", //index 30

  "./HarapAlb/AcTabere/1.png", //index 31
  "./HarapAlb/AcTabere/2.png",
  "./HarapAlb/AcTabere/3.png",
  "./HarapAlb/AcTabere/4.png",
  "./HarapAlb/AcTabere/5.png",
  "./HarapAlb/AcTabere/6.png",
  "./HarapAlb/AcTabere/7.png",
  "./HarapAlb/AcTabere/8.png",
  "./HarapAlb/AcTabere/9.png",
  "./HarapAlb/AcTabere/10.png",
  "./HarapAlb/sword_1_hor.png", //index 41

  "./HarapAlb/Profesori/1.png", //index 42 - profesori
  "./HarapAlb/king.png", //index 43 - profesori
  "./HarapAlb/modal.png", //index 44 - profesori

  "./HarapAlb/Tabere/1.png", //index 45 - tabere
  "./HarapAlb/horse.png", //index 46 - tabere

  "./HarapAlb/Icons/1.png", //index 47 - tabere
  "./HarapAlb/Icons/2.png", //48
  "./HarapAlb/Icons/3.png", //49

  "./HarapAlb/Icons/4.png", //50 - social
  "./HarapAlb/Icons/5.png", //51
  "./HarapAlb/Icons/6.png", //52
  "./HarapAlb/Icons/7.png", //53

  "./HarapAlb/Hero/hero_2.png", //index 54
  "./HarapAlb/Hero/hero_3.png", //index 55
  "./HarapAlb/Hero/hero_4.png", //index 56

  "./HarapAlb/Profesori/2.png", //index 57 - profesori
  "./HarapAlb/Profesori/3.png", //index 58 - profesori
  "./HarapAlb/Profesori/4.png", //index 59 - profesori
  "./HarapAlb/Profesori/5.png", //index 60 - profesori
  "./HarapAlb/Profesori/6.png", //index 61 - profesori
  "./HarapAlb/Profesori/default.png", //index 62 - profesori

  // tabere
  "./HarapAlb/Tabere/2.png", //index 62 - tabere
  "./HarapAlb/Tabere/3.png", //index 63 - tabere
  "./HarapAlb/Tabere/4.png", //index 64 - tabere
  "./HarapAlb/Tabere/5.png", //index 65 - tabere
  "./HarapAlb/Tabere/6.png", //index 66 - tabere

  "./HarapAlb/Profesori/7.png", //index 68 - profesori
  "./HarapAlb/Profesori/fondator.png", //69
  "./HarapAlb/Tabere/7.png", //70
  "./HarapAlb/AcTabere/11.png", //71

  "./HarapAlb/Profesori/8.png", //72
  "./HarapAlb/Profesori/9.png", //73

  "./HarapAlb/Icons/check.png", //74
  "./HarapAlb/firma_8.png", //75
  "./HarapAlb/AcTabere/12.png", //76
  "./HarapAlb/AcTabere/13.png", //77
  "./HarapAlb/AcTabere/14.png", //78
];

export const core = {
  websiteName: "Cezara build",
  description: "desc cezara",
  content: {
    appBar: (
      <Omnitrix.Module_Wrappers_Navbar_2.item
        logic={undefined}
        resources={{
          links: [
            {
              title: "ACASĂ",
              toId: "#acasa",
            },
            {
              title: "DESPRE",
              toId: "#despre",
            },
            {
              title: "ACTIVITĂȚI",
              toId: "#activitati",
            },
            {
              title: "IMAGINI",
              toId: "#imagini",
            },
            {
              title: "MATERII",
              toId: "#materii",
            },
            {
              title: "DESFĂȘURARE",
              toId: "#desc-activitati",
            },
            {
              title: "PROFESORI",
              toId: "#profesori",
            },
            {
              title: "TABERE",
              toId: "#tabere",
            },
          ],
        }}
        themeParam={theme}
        customStyle={{
          bgColor: undefined,
          actionColor: undefined,
        }}
        revealAnimationParam={RevealAnimations.fadeIn}
        atoms={{
          button: {
            item: Omnitrix.Atom_Buttons_Btn_1.item,
            props: {
              logic: {
                onClick: () => {
                  window.location.href = "https://blog.centrulharapalb.ro/";
                },
              },
              themeParam: theme,
              resources: {
                text: "BLOG",
              },
              customStyle: {
                color: "white",
                padding: "10px 30px",
                borderRadius: "15px",
                margin: "0px 20px",
              },
            },
          },
        }}
      />
    ),
    footer: null,
    footer: (
      <Omnitrix.Module_Wrappers_Footer_1.item
        logic={undefined}
        resources={{
          logo: imagesHarap[0],
          title: "Centrul Educațional HARAP ALB Satu Mare",
          phone: "+40 745 354 236",
          mail: "centrulharapalb@gmail.com",
          refs: [
            {
              name: "ACASA",
              link: "#acasa",
            },
            {
              name: "DESPRE",
              link: "#despre",
            },
            {
              name: "ACTIVITĂȚI",
              link: "#activitati",
            },
            {
              name: "IMAGINI",
              link: "#imagini",
            },
            {
              name: "MATERII",
              link: "#materii",
            },
            {
              name: "PROFESORI",
              link: "#profesori",
            },
            {
              name: "TABERE",
              link: "#tabere",
            },
          ],
          social: [
            {
              name: "Facebook",
              link: "https://www.facebook.com/centrulharapalb",
            },
            {
              name: "Instagram",
              link: "https://www.instagram.com/centrulharapalb/",
            },
            // {
            //     name: 'TikTok',
            //     link: 'https://www.google.com'
            // },
          ],
          copyrights: (
            <>
              © 2023 Centrul HARAP ALB.
              <br />
              Toate drepturile rezervate.
            </>
          ),
        }}
        themeParam={theme}
        customStyle={{
          bg: {
            backgroundColor: theme.colors.color_30,
          },
        }}
        revealAnimationParam={RevealAnimations.fadeIn}
        atoms
      />
    ),
    pages: [
      {
        url: "/",
        metadata: { title: "Centrul Educațional HARAP ALB", desc: "" },
        components: [
          <Omnitrix.Module_Layers_Widget_6.item
            logic={{
              id: "acasa",
            }}
            resources={{
              logo: imagesHarap[0],
              title: "Centrul Educațional HARAP ALB",
              desc: (
                <>
                  Crearea și fundamentarea unui concept nou <br />
                  în educație
                </>
              ),
              decor: imagesHarap[1],
              sponsors: [
                imagesHarap[5],
                imagesHarap[2],
                imagesHarap[3],
                imagesHarap[6],
                imagesHarap[7],
                imagesHarap[75],
                "./HarapAlb/firma_7.png",
              ],
              slider: [
                imagesHarap[55],
                imagesHarap[10],
                imagesHarap[54],
                ,
                imagesHarap[56],
                "./HarapAlb/Hero/hero_5.png",
              ],
              sward: imagesHarap[8],
            }}
            themeParam={theme}
            customStyle={{
              bg: {
                backgroundImage: `url('${imagesHarap[9]}')`,
              },
            }}
            revealAnimationParam={{
              parent: RevealAnimations.slideRight,
            }}
            atoms={undefined}
          />,
          <Omnitrix.Module_Layers_widget_7.item
            logic={{
              id: "despre",
            }}
            resources={{
              sword: imagesHarap[11],
              img: imagesHarap[12],
              title: "MISIUNEA NOASTRĂ",
              content: (
                <>
                  Formarea unei personalități creatoare, cu atitudine de <b>ZIDITOR</b>. Ziditorul
                  este <b>COPILUL MEȘTER</b>, copilul creativ, capabil să ofere o variantă unică, de
                  construcție, cu sens umanist, în rezolvarea sarcinilor instructiv-educative din
                  activitățile în care este integrat.
                </>
              ),
            }}
            themeParam={theme}
            customStyle={{
              bg: {
                backgroundImage: `url('./HarapAlb/bgs/2.png')`,
                backgroundSize: "cover",
              },
            }}
            revealAnimationParam={{
              parent: RevealAnimations.slideRight,
            }}
            atoms={{
              button: {
                item: Omnitrix.Atom_Buttons_Btn_1.item,
                props: {
                  logic: {
                    onClick: () => {
                      // document.getElementById('activitati').scrollIntoView();
                      window.location =
                        "https://docs.google.com/forms/d/e/1FAIpQLSeUyno-B8XPwuIKwz8mti8qEugKGuyPX1STiShvkPGAdQt7Uw/viewform?usp=send_form";
                    },
                  },
                  themeParam: theme,
                  resources: {
                    text: "REZERVĂ LOC",
                  },
                  customStyle: {
                    backgroundColor: theme.colors.color_30,
                    color: "white",
                    padding: "20px 50px",
                    borderRadius: "15px",
                    fontSize: "1.2rem",
                    borderRadius: "40px",
                  },
                },
              },
            }}
          />,
          <Omnitrix.Module_Layers_widget_8.item
            logic={{
              id: "activitati",
            }}
            resources={{
              // title: <>Activități ale Centrului Educațional <br></br> Harap Alb</>,
              items: [
                {
                  name: `Cercetare curriculum transdisciplinar`,
                  img: imagesHarap[14],
                },
                {
                  name: `Tabere de vară`,
                  img: imagesHarap[15],
                },
                {
                  name: `Diseminare / formare cadre didactice Înv. primar & secundar`,
                  img: imagesHarap[16],
                },
                {
                  name: `Consiliere / parenting`,
                  img: imagesHarap[17],
                },
              ],
              special: {
                left: "Gazda Copiilor",
                img: imagesHarap[18],
                right: "Motanul Domino Dominic Batman",
              },
              decor: imagesHarap[8], //sabie
              // decor2: imagesHarap[19], //decor imagine
            }}
            themeParam={theme}
            customStyle={{
              bg: {
                backgroundImage: `url('./HarapAlb/bgs/3.png')`,
                backgroundSize: "cover",
              },
            }}
            revealAnimationParam={{
              parent: RevealAnimations.slideRight,
            }}
            atoms={{
              title: {
                item: Omnitrix.Atom_Title_1.item,
                props: {
                  themeParam: theme,
                  resources: {
                    title: "Activități ale Centrului Educațional HARAP ALB",
                    decor: imagesHarap[19],
                  },
                  customStyle: {},
                },
              },
            }}
          />,
          <Omnitrix.Module_Layers_Widget_5.item
            logic={{
              id: "imagini",
            }}
            resources={{
              images: [
                {
                  src: imagesHarap[20],
                  origin: imagesHarap[20],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: imagesHarap[21],
                  origin: imagesHarap[21],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: imagesHarap[22],
                  origin: imagesHarap[22],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: imagesHarap[23],
                  origin: imagesHarap[23],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: imagesHarap[24],
                  origin: imagesHarap[24],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: imagesHarap[25],
                  origin: imagesHarap[25],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: imagesHarap[26],
                  origin: imagesHarap[26],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: imagesHarap[27],
                  origin: imagesHarap[27],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: imagesHarap[29],
                  origin: imagesHarap[29],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: imagesHarap[30],
                  origin: imagesHarap[30],
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/12.png",
                  origin: "./HarapAlb/Galerie/12.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/13.png",
                  origin: "./HarapAlb/Galerie/13.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/14.png",
                  origin: "./HarapAlb/Galerie/14.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/15.png",
                  origin: "./HarapAlb/Galerie/15.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/16.png",
                  origin: "./HarapAlb/Galerie/16.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/17.png",
                  origin: "./HarapAlb/Galerie/17.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/18.png",
                  origin: "./HarapAlb/Galerie/18.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/12.png",
                  origin: "./HarapAlb/Galerie/12.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/19.png",
                  origin: "./HarapAlb/Galerie/19.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/20.png",
                  origin: "./HarapAlb/Galerie/20.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/21.png",
                  origin: "./HarapAlb/Galerie/21.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/22.png",
                  origin: "./HarapAlb/Galerie/22.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/23.png",
                  origin: "./HarapAlb/Galerie/23.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/24.png",
                  origin: "./HarapAlb/Galerie/24.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/25.png",
                  origin: "./HarapAlb/Galerie/25.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/26.png",
                  origin: "./HarapAlb/Galerie/26.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
                {
                  src: "./HarapAlb/Galerie/27.png",
                  origin: "./HarapAlb/Galerie/27.png",
                  width: 170,
                  height: 184,
                  caption: "Caption",
                },
              ],
            }}
            themeParam={undefined}
            customStyle={{
              bg: {
                backgroundImage: `url('./HarapAlb/bgs/4.png')`,
                backgroundSize: "cover",
              },
            }}
            revealAnimationParam={RevealAnimations.slideLeft}
            atoms={{
              title: {
                item: Omnitrix.Atom_Title_1.item,
                props: {
                  themeParam: theme,
                  resources: {
                    title: "Galerie",
                    decor: imagesHarap[19],
                  },
                  customStyle: {
                    parent: undefined,
                  },
                  revealAnimationParam: RevealAnimations.slideRight,
                },
              },
            }}
          />,
          <Omnitrix.Module_Layers_widget_9.item
            logic={{
              id: "materii",
            }}
            resources={{
              decor: imagesHarap[41],
              specialItems: [
                {
                  img: imagesHarap[37],
                  title: "GRUPUL DE EXCELENȚĂ MATE-OLIMPIADĂ",
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSc-yL2J_2-0nyfprcCT3IoOJ_ahMQ8rmJFTUbquakCBpv5Iqw/viewform",
                },
                {
                  img: imagesHarap[71],
                  title: "GRUPUL DE EXCELENȚĂ ȘAH -OLIMPIADĂ-",
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSc-yL2J_2-0nyfprcCT3IoOJ_ahMQ8rmJFTUbquakCBpv5Iqw/viewform",
                },
                {
                  img: imagesHarap[76],
                  title: "GRUPUL ÎNCEPĂTORI ENGLEZĂ",
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSc-yL2J_2-0nyfprcCT3IoOJ_ahMQ8rmJFTUbquakCBpv5Iqw/viewform",
                },
                {
                  img: imagesHarap[77],
                  title: "GRUPUL ÎNCEPĂTORI GERMANĂ",
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSc-yL2J_2-0nyfprcCT3IoOJ_ahMQ8rmJFTUbquakCBpv5Iqw/viewform",
                },
                {
                  img: imagesHarap[78],
                  title: (
                    <>
                      GRUPUL DE LIMBA ROMÂNĂ <br></br>PENTRU COMUNITATEA MAGHIARĂ
                    </>
                  ),
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSc-yL2J_2-0nyfprcCT3IoOJ_ahMQ8rmJFTUbquakCBpv5Iqw/viewform",
                },
              ],
              items: [
                {
                  img: imagesHarap[31],
                  title: "Ora digitală",
                },
                {
                  img: imagesHarap[32],
                  title: "Ora de artă fotografică",
                },
                {
                  img: imagesHarap[33],
                  title: "Ora de istorie",
                },
                {
                  img: imagesHarap[34],
                  title: "Ora de educație sanitară",
                },
                {
                  img: imagesHarap[35],
                  title: "Ora de limba maghiară",
                },
                {
                  img: imagesHarap[36],
                  title: "Ora de dezvoltare personală",
                },

                {
                  img: imagesHarap[38],
                  title: "Ora de limba engleză",
                },
                {
                  img: imagesHarap[39],
                  title: "Ora de PetSchool",
                },
                {
                  img: imagesHarap[40],
                  title: "Ora de HAR up ! ALB",
                },
              ],
            }}
            themeParam={theme}
            customStyle={{
              bg: {
                backgroundImage: `url('./HarapAlb/bgs/5.png')`,
                backgroundSize: "cover",
              },
            }}
            revealAnimationParam={undefined}
            atoms={{
              title: {
                item: Omnitrix.Atom_Title_1.item,
                props: {
                  themeParam: theme,
                  resources: {
                    title: "Tipuri de activități ale taberelor de vară",
                    decor: imagesHarap[19],
                  },
                  customStyle: {
                    // border: '1px solid red'
                  },
                },
              },
            }}
          />,
          <Omnitrix.Module_Layers_widget_13.item
            logic={{
              id: "desc-activitati",
            }}
            resources={{
              img: imagesHarap[26],
              desc: (
                <>
                  În <b>CENTRUL EDUCAȚIONAL HARAP ALB</b> copilul începe ziua cu BodyArt Kids -ora
                  de mișcare- apoi o continuă cu HARup! ALB ca să învețe să "se ridice" după ceea ce
                  el consideră a fi eșec. Ziua continuă cu vizita în atelierul Schneiderului pentru
                  lecția de Istorie. Gradina de legume din spațiul generos al centrului cheamă
                  copiii la gradinărit, apoi la gătit în bucătaria veche de peste 50 de ani,
                  reeditând rețete din bătrâni. <br></br>
                  <br></br>Clubul de lectură al centrului are un număr impresionant de carte
                  nou-aparută și carte din ediție veche. Matematica și <b>GRUPA de EXCELENȚĂ </b> de
                  mate și șah se ocupă de soluții concrete de reciclare, cu rezolvare de probleme
                  practice, dar și de concurs. Olimpiada de Șah aduce copiilor premii consistente,
                  iar Dominic- motanul iubitor de copii al centrului- ne însotește în toată această
                  călatorie magică, de poveste.
                </>
              ),
            }}
            themeParam={theme}
            customStyle={{
              bg: {
                backgroundImage: `url('./HarapAlb/bgs/5.png')`,
                backgroundSize: "cover",
              },
            }}
            revealAnimationParam={undefined}
            atoms={{
              title: {
                item: Omnitrix.Atom_Title_1.item,
                props: {
                  themeParam: theme,
                  resources: {
                    title: "Despre activități",
                    decor: imagesHarap[19],
                  },
                  customStyle: {
                    // border: '1px solid red'
                  },
                },
              },
            }}
          />,
          <Omnitrix.Module_Layers_widget_14.item
            logic={{
              id: "Valori si concepte dezvoltate de centru",
            }}
            resources={{
              items: [
                "EXCELENȚĂ în Matematică",
                "Tradiție și conservarea valorilor tradiționale românești",
                "TransientArt",
                "ArsGraffiti",
                "Livresq",
                "Bookflix",
                "Educație digitală",
                "PetSchool",
                "Green Education/ reciclare",
                "BodyArt Kids",
                "Toleranța și respect interetnic",
                "Educație pentru sanătate",
              ],
              checkImg: imagesHarap[74],
            }}
            themeParam={theme}
            customStyle={{
              bg: {
                backgroundImage: `url('./HarapAlb/bgs/5.png')`,
                backgroundSize: "cover",
              },
            }}
            revealAnimationParam={undefined}
            atoms={{
              title: {
                item: Omnitrix.Atom_Title_1.item,
                props: {
                  themeParam: theme,
                  resources: {
                    title: "Valori și concepte dezvoltate de centru",
                    decor: imagesHarap[19],
                  },
                  customStyle: {
                    // border: '1px solid red'
                  },
                },
              },
            }}
          />,
          <Omnitrix.Module_Layers_widget_10.item
            logic={{
              id: "profesori",
            }}
            resources={{
              swordItem: imagesHarap[41],
              img: imagesHarap[43],
              modal: imagesHarap[44],
              specialItem: {
                name: "Ruba Loredana",
                headline: "Fondator centru",
                img: imagesHarap[69],
                desc: "",
              },
              itemsTop: [
                {
                  name: "Dr. Dana Seucan",
                  headline: "Profa de consiliere",
                  img: imagesHarap[42],
                  desc: "Consilier/Terapeut/Formator și expert in Parenting, Științele Educației, Psihologia școlarului mic și Experent in echipa de cercetare Curriculum/Concept nou HARup! ALB",
                },
                {
                  name: "Oana Tarța",
                  headline: "Profa de artă fotografică ",
                  img: imagesHarap[57],
                  desc: "Artist plastic si fotograf profesionist",
                },
                {
                  name: "Dr. Elena Bonea",
                  headline: "Profa de pet-school",
                  img: imagesHarap[73],
                  desc: "Dr. Veterinar MOBOVET - Mentor Educația pentru Pets",
                },
                {
                  name: "Rusz Corina",
                  headline: "Profa de educație digitală",
                  img: imagesHarap[58],
                  desc: "Ambasador Erasmus România și formator CRED în cadrul CCD Satu Mare.",
                },
              ],
              itemsBot: [
                {
                  name: "Erika Kato",
                  headline: "Profa de educație sanitară",
                  img: imagesHarap[59],
                  desc: 'Coordonator echipe la concursurile naționale de Prim Ajutor și Cadru medical la Scoala Gimnazială "Grigore Moisil" Satu Mare.',
                },
                {
                  name: "Eniko Nezezun",
                  headline: "Profa de limba maghiară",
                  img: imagesHarap[72],
                  desc: "Presedinta Aranykapu Kulturalis Egyesulet și artist meșteșugar in olărit și lână",
                },
                {
                  name: "Cosmin Balog",
                  headline: "Profu de limba engleză",
                  img: imagesHarap[60],
                  desc: "Traducător/formator și Owner Project Manager la Big Talk",
                },
                // {
                //     name: 'Petru Braica',
                //     headline: 'Profu’ de mate',
                //     img: imagesHarap[62],
                //     desc: ' '
                // },
                {
                  name: "Cătălin Ardelean",
                  headline: "Profu de șah",
                  img: imagesHarap[61],
                  desc: "Antrenor coordonator al Centrului Regional de Șah Satu Mare",
                },
                {
                  name: "Dodea Aurora",
                  headline: "Profa de mate",
                  img: imagesHarap[68],
                  desc: `Coordonator grupa -matematică-  în cadrul Centrului  Județean de Excelență -Satu Mare din 2018 până în prezent.
                                    Autor de probleme publicate în revista de „Gazeta Matematică`,
                },
              ],
            }}
            themeParam={theme}
            customStyle={{
              bg: {
                backgroundImage: `url('./HarapAlb/bgs/6.png')`,
                backgroundSize: "cover",
              },
            }}
            atoms={{
              title: {
                item: Omnitrix.Atom_Title_1.item,
                props: {
                  themeParam: theme,
                  resources: {
                    title: "Colaboratorii noștri",
                    decor: imagesHarap[19],
                  },
                  customStyle: {},
                },
              },
            }}
          />,
          <Omnitrix.Module_Layers_widget_11.item
            logic={{
              id: "tabere",
            }}
            resources={{
              img: imagesHarap[46],
              items: [
                {
                  name: "Tabăra Opal",
                  headline: "24.06.2024 - 28.06.2024",
                  img: imagesHarap[45],
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSeUyno-B8XPwuIKwz8mti8qEugKGuyPX1STiShvkPGAdQt7Uw/viewform",
                },
                {
                  name: "Tabăra Safir",
                  headline: "01.07.2024 - 05.07.2024",
                  img: imagesHarap[63],
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSeUyno-B8XPwuIKwz8mti8qEugKGuyPX1STiShvkPGAdQt7Uw/viewform",
                },
                {
                  name: "Tabăra Smarald",
                  headline: "08.07.2024 - 12.07.2024",
                  img: imagesHarap[64],
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSeUyno-B8XPwuIKwz8mti8qEugKGuyPX1STiShvkPGAdQt7Uw/viewform",
                },
                {
                  name: "Tabăra Rubin",
                  headline: "15.07.2024 - 19.07.2024",
                  img: imagesHarap[65],
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSeUyno-B8XPwuIKwz8mti8qEugKGuyPX1STiShvkPGAdQt7Uw/viewform",
                },
                {
                  name: "Tabăra Diamant",
                  headline: "29.07.2024 - 03.08.2024",
                  img: imagesHarap[66],
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSeUyno-B8XPwuIKwz8mti8qEugKGuyPX1STiShvkPGAdQt7Uw/viewform",
                },
                {
                  name: "Tabăra Topaz",
                  headline: "Urmează...",
                  img: imagesHarap[67],
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSeUyno-B8XPwuIKwz8mti8qEugKGuyPX1STiShvkPGAdQt7Uw/viewform",
                },
                {
                  name: "TABĂRA DRACULA",
                  headline: "Vacanța de toamnă",
                  img: imagesHarap[70],
                  link: "https://docs.google.com/forms/d/e/1FAIpQLScuEsvn0so1-Qb9qRvxcu06Fg_-fpkBZN5ovcienywXn3KtkQ/viewform",
                },
              ],
            }}
            themeParam={theme}
            customStyle={{
              bg: {
                backgroundImage: `url('./HarapAlb/bgs/6.png')`,
                backgroundSize: "cover",
              },
            }}
            revealAnimationParam={RevealAnimations.fadeIn}
            atoms={{
              button: {
                item: Omnitrix.Atom_Buttons_Btn_1.item,
                props: {
                  // logic:{
                  //     onClick: ()=>{
                  //         // window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeUyno-B8XPwuIKwz8mti8qEugKGuyPX1STiShvkPGAdQt7Uw/viewform'
                  //     }
                  // },
                  themeParam: theme,
                  resources: {
                    text: "REZERVĂ LOC",
                  },
                  customStyle: {
                    backgroundColor: theme.colors.color_30,
                    color: "white",
                    padding: "10px 30px",
                    borderRadius: "15px",
                    fontSize: "1rem",
                    borderRadius: "40px",
                    marginTop: "25px",
                  },
                },
              },
              title: {
                item: Omnitrix.Atom_Title_1.item,
                props: {
                  themeParam: theme,
                  resources: {
                    title: "Tabere 2023",
                    decor: imagesHarap[19],
                  },
                  customStyle: {},
                },
              },
            }}
          />,
          <Omnitrix.Module_Layers_widget_12.item
            logic={undefined}
            resources={{
              decor1: imagesHarap[19], //picture decor
              decor2: imagesHarap[1], //green totem
              map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3790.3665320450723!2d22.86633214204241!3d47.79707736117155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473805c87a23bcb1%3A0x431d31e18c4442d5!2sStrada%20Marsilia%2029%2C%20Satu%20Mare!5e0!3m2!1sen!2sro!4v1684675526969!5m2!1sen!2sro",
              contact: [
                {
                  name: "Strada Marsilia, Nr. 29",
                  icon: imagesHarap[47],
                },
                {
                  name: "+40 745 354 236",
                  icon: imagesHarap[48],
                },
                {
                  name: "ruba.loredana@gmail.com",
                  icon: imagesHarap[49],
                },
              ],
              social: [
                {
                  icon: imagesHarap[50],
                  link: "https://www.instagram.com/centrulharapalb",
                },

                {
                  icon: imagesHarap[52],
                  link: "https://www.facebook.com/centrulharapalb",
                },
                // {
                //     icon: imagesHarap[53],
                //     link: 'https://www.google.com'
                // },
              ],
            }}
            themeParam={theme}
            customStyle={{
              bg: {
                backgroundImage: `url('${imagesHarap[13]}')`,
              },
            }}
            revealAnimationParam={RevealAnimations.fadeIn}
            atoms={{
              title: {
                item: Omnitrix.Atom_Title_1.item,
                props: {
                  themeParam: theme,
                  resources: {
                    title: "Ia legătura cu noi",
                    decor: imagesHarap[19],
                  },
                  customStyle: {},
                },
              },
            }}
          />,

          // <Omnitrix.Module_Layers_Widget_1.item
          //     resources={{
          //         items:  undefined,
          //     }}
          //     themeParam={undefined}
          //     customStyle={{
          //         title: undefined,
          //         headline: undefined,
          //         desc: undefined,
          //         parentBg: undefined, // backgroundImage: `url('${defaultImage[1]}')` or backgroundColor: 'rgba(0,0,0,0.2)'
          //         itemContainer: undefined,
          //         bgCard: undefined //backgroundImage: `url('${defaultImage[0]}')` or backgroundColor: 'rgba(0,0,0,0.2)'
          //     }}
          //     revealAnimationParam={{
          //         parent: RevealAnimations.slideRight,
          //     }}
          //     atoms={{
          //         button: undefined,
          //         // button: {
          //         //     item: Omnitrix.Atom_Buttons_Btn_1,
          //         //     props: {
          //         //         resources: {
          //         //             text: 'Custoom'
          //         //         }
          //         //     }
          //         // }
          //     }}
          // />,
        ],
      },
    ],
  },
};
// export const core = {
//     websiteName: 'Test Arhitecture',
//     description: 'Test Arhitecture',
//     content:{
//         appBar: null,
//         footer: null,
//         pages:[
//             // DEV MODE (!!! delete this when creating product build)
//             {
//                 url:'/morgana',
//                 metadata:{title:'',desc:''},
//                 components:[<DashboardComponent/>]
//             },
//             {
//                 url: '/',
//                 metadata: {
//                     title: 'Test Arhitecture',
//                     desc: 'Desc Test Arhitecture'
//                 },
//                 components: [
//                     <Omnitrix.Module_Wrappers_Navbar_1.item
//                         resources={{
//                             mapIcon: undefined,
//                             locationName: undefined,
//                             phoneIcon: undefined,
//                             phoneName: undefined,
//                             iconsColor: undefined,//ex: 'dd9a34'
//                             logo: undefined,
//                             links: undefined
//                         }}
//                         themeParam={undefined}
//                         customStyle={{
//                             iconsColor: undefined,//ex: 'dd9a34'
//                             primaryFont: undefined,
//                             secondaryFont: undefined,
//                             fontColor: undefined,
//                             fontColorOnScroll: undefined,
//                             bgFirstColor: undefined,
//                             bgColorOnScroll: undefined,
//                             mobileFontSize: undefined,
//                             borderItemsHover: undefined,
//                             hoverTextColor: undefined,
//                             hoverAnim: 1,
//                             borderBottomColor: undefined,
//                             containerPadding: undefined, //'10px 5px'
//                             logoHeight: undefined
//                         }}
//                     />,
//                     <Omnitrix.Module_Layers_Widget_1.item
//                         resources={{
//                             items:  undefined,
//                         }}
//                         themeParam={undefined}
//                         customStyle={{
//                             title: undefined,
//                             headline: undefined,
//                             desc: undefined,
//                             parentBg: undefined, // backgroundImage: `url('${defaultImage[1]}')` or backgroundColor: 'rgba(0,0,0,0.2)'
//                             itemContainer: undefined,
//                             bgCard: undefined //backgroundImage: `url('${defaultImage[0]}')` or backgroundColor: 'rgba(0,0,0,0.2)'
//                         }}
//                         revealAnimationParam={{
//                             parent: RevealAnimations.slideRight,
//                         }}
//                         atoms={{
//                             button: undefined,
//                             // button: {
//                             //     item: Omnitrix.Atom_Buttons_Btn_1,
//                             //     props: {
//                             //         resources: {
//                             //             text: 'Custoom'
//                             //         }
//                             //     }
//                             // }
//                         }}
//                     />,

//                     <Omnitrix.Module_Layers_Widget_3.item
//                         logic={undefined}
//                         resources={{
//                             pictures:[...Array(10).keys()].map(()=>defaultImage[0])
//                         }}
//                         themeParam={theme}
//                         customStyle={{
//                             parent: undefined, // backgroundImage: `url('${defaultImage[1]}')` or backgroundColor: 'rgba(0,0,0,0.2)'
//                             buttonsColor: undefined
//                         }}
//                         revealAnimationParam={{
//                             parent: RevealAnimations.fadeIn
//                         }}
//                     />,
//                     <Omnitrix.Module_Layers_Widget_5.item
//                         resources={{
//                             images: undefined
//                             // [
//                             //     {
//                             //         src: defaultImage[0],
//                             //         width: 300,
//                             //         height: 300,
//                             //     }]
//                         }}
//                         themeParam={undefined}
//                         customStyle={{
//                             bgColor: undefined
//                         }}
//                         revealAnimationParam={{
//                             parent: undefined
//                         }}
//                         atoms={undefined}
//                     />,
//                     <Omnitrix.Module_Layers_Widget_2.item
//                         logic={undefined}
//                         resources={{
//                             defaltIcon: defaultImage[0],
//                             icons:undefined,
//                             // [{
//                             //     icon: defaultLogo,
//                             //     link: 'https://www.google.com'
//                             // },
//                             // {
//                             //     icon: defaultLogo,
//                             //     link: 'https://www.google.com'
//                             // },
//                             // {
//                             //     icon: defaultLogo,
//                             //     link: 'https://www.google.com'
//                             // }]
//                         }}
//                         themeParam={undefined}
//                         customStyle={{
//                             border: undefined,
//                             bgColor: undefined
//                         }}
//                     />,
//                 ]
//             },
//         ]
//     }
// }
