import React,{useState, useEffect, useRef} from 'react';
import { DefaultButton, DefaultRevealAnimation, defaultBgImage, defaultImage, defaultLogo, defaultTheme } from '../../../Style/Theme';
import { motion } from 'framer-motion';
import { useOnScreen } from '../../../core/utils/useOnScreen';
import Modal from '@mui/material/Modal';
import './coreStyle.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const defaultItems = [
    {
        name: 'Default Name',
        headline: 'Default Headline',
        img: defaultBgImage[1],
        link: 'https://www.google.com'
    },
    {
        name: 'Default Name',
        headline: 'Default Headline',
        img: defaultBgImage[1],
        link: 'https://www.google.com'
    },
]
export default function CoreModule({
    logic, 
    resources, 
    themeParam,
    customStyle,
    revealAnimationParam,
    atoms
})
{
    //logic
    const id = logic?.id ? logic.id : 'widget-11-id';

    //resources
    // const title = resources?.title ? resources.title : 'Default Title';
    const items = resources?.items ? resources.items : defaultItems
    const img = resources?.img ? resources.img : defaultImage[1];

    //theme
    const theme = themeParam ? themeParam:defaultTheme;

    //customStyle
    const bg = customStyle?.bg ? customStyle.bg : {backgroundImage: `url('${defaultImage[1]}')`}

    //animations
    let parentAnimation = revealAnimationParam?.parent !== undefined ? revealAnimationParam.parent: DefaultRevealAnimation
    const { hidden, show } = parentAnimation;

    // atoms
    const title = atoms?.title !== undefined ? atoms.title : undefined;
    const button = atoms?.button !== undefined ? atoms.button : undefined;

    //trigger reveal animation logic
    const [alreadyVisible, setAlreadyVisible] = useState(false);
    const ref = useRef(null);
    const isVisible = useOnScreen(ref, 0.5);
    useEffect(()=>{
        if(isVisible == true && alreadyVisible == false)
        {
            setAlreadyVisible(true);
        }
    },[isVisible])

    return(
        <div
            id={id}
            ref={ref}
            style={{
                minHeight: '40vh',
            }}
        >
                {alreadyVisible  == true ?  (
            <motion.div 
                variants={{ hidden, show }} initial="hidden" animate="show"
                className='layers_widget_11-container'
                style={{
                    fontFamily: theme.fonts.title,
                    ...bg
                }}
                >
                    {
                        title?.item ? 
                        <title.item {...title.props}/>
                        :
                        <p>Default title</p>
                    }
                    <div className='layers_widget_11-container-left-content'>
                        <div className='layers_widget_11-container-content-left'>
                            <img src={img} alt="decor"/>
                        </div>
                        <div className='layers_widget_11-container-content-right'>
                            {items.map((el)=>{
                                return(<Item {...el} button={button} />)
                            })}
                        </div>
                    </div>
                    
                </motion.div>):null}
        </div>
    )
}

const Item =({name, headline, img, link, button})=>{
    return(
        <div className='layers_widget_11-container-content-right-item'>
            <div className='layers_widget_11-container-content-right-item-name'>
                <span >{name}</span>
            </div>
            <div className='layers_widget_11-container-content-right-item-head'>
                <span>{headline}</span>
            </div>
            <img src={img}/>
            {
                button?.item ? 
                <button.item {...button.props} 
                    logic={{
                        onClick:()=>{
                            window.location.href = link
                        }
                    }}
                />
                :
                <button>Default Button</button>
            }

        </div>
    )
}
